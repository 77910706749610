* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  background: #f0f0f0;
}


.expandedCard {
  position: relative;
  z-index: 1;
}

.gridItem {
  transition: transform 0.3s ease-in-out;
}


.MuiInputLabel-formControl {
  background-color: #F1F8E9 !important;
  padding: 0px 5px 0px 5px !important;
  margin-left: -5px !important;
  border-radius: 9px !important;
}

.FullHeightContainer {
  /* This is effectively 100% of the *current* window.innerHeight */
  height: calc(var(--vh, 1vh) * 100);

  /* If you want to disable vertical scrolling completely: */
  overflow: hidden;
}